.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(240, 237, 230, 0.6);
  overflow-x: hidden;

  .home-banner {
    position: relative;
    width: 100%;
    background-image: url("../../assets/home-banner-bg.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .banner-text_container {
      position: relative;
      margin: 11.5rem auto 4rem auto;
      width: 90%;
      font-weight: 800;
      font-size: 114px;
      color: #ffbdd4;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .banner-text {
        text-align: center;
        width: 100%;
        font-size: 6rem;
      }
    }
  }

  .main-content__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title-container {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        width: 70%;
        color: #009966;
        font-size: 1.4rem;
        font-weight: bold;
        margin: 3rem auto;
        text-align: center;
      }
    }

    .problem-solution__container {
      display: flex;
      width: 95%;
      justify-content: space-evenly;
      align-items: flex-start;

      .problem-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding: 4rem 0 4.15rem 0;
        width: 45%;
        border-radius: 5px;
        border-bottom: 10px solid #ff6408;
        box-shadow: 6px 8px 15px lightgray;

        .heading {
          font-size: 0.95rem;
        }

        .problem {
          color: #ff6408;
          text-transform: uppercase;
        }

        .description {
          width: 70%;
          text-align: center;
        }
      }

      .solution-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding: 4rem 0 6.5rem 0;
        width: 45%;
        border-radius: 5px;
        border-bottom: 10px solid #ffbdd4;
        box-shadow: 6px 8px 15px lightgray;

        .heading {
          font-size: 0.95rem;
        }

        .solution {
          color: #ffbdd4;
          text-transform: uppercase;
        }

        .description {
          width: 70%;
          text-align: center;
        }
      }
    }

    .btn-container {
      margin: 2.5rem 0;
      width: 40%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .filled {
        border: none;
        padding: 0.53rem 1rem;
        border-radius: 20px;
        background-color: #009966;
        color: #f0ede6;
        font-weight: 600;
        transition: all 0.2s ease-in-out;

        .link {
          text-decoration: none;
          color: white;
          font-weight: bold;
        }
      }

      .filled:hover {
        padding: 0.5rem 1rem;
        border: 2px solid #009966;
        background-color: #f0ede6;

        .link {
          color: #009966;
          padding-bottom: 0.1rem;
          border-bottom: 2px solid #ffbdd4;
        }
      }
    }

    .trusted-container {
      width: 95%;
      border-top: 3px solid #009966;
      border-bottom: 3px solid #009966;
      margin-bottom: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;

      .description {
        color: #009966;
        font-size: 3.7rem;
        font-weight: bolder;
        width: 60%;
        text-align: center;
      }
    }

    .vision-container {
      width: 100%;
      padding: 12rem 0;
      color: white;
      background-color: #009966;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .vector-1 {
        position: absolute;
        right: 9.5%;
        top: 30%;
        width: 68%;
        z-index: 1;
      }

      .vector-2 {
        position: absolute;
        right: 16%;
        top: 80.6%;
        width: 60%;
        z-index: 1;
      }

      .vector-container {
        position: relative;
        width: 100%;
        z-index: 1;

        .vector-1 {
          position: absolute;
          right: 1rem;
          top: 2rem;
          width: 38%;
        }

        .vector-2 {
          position: absolute;
          right: 11.3rem;
          top: 24.9rem;
          width: 65%;
        }
      }

      .heading-container {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .heading {
          margin: 0 0 0.5rem 0;
          font-size: 3rem;
        }

        .sub-heading {
          margin: 0 0 0.5rem 0;
          font-size: 2rem;
        }
      }

      .vision-text {
        width: 80%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
          font-weight: 200;
          width: 100%;
          font-size: 1.7rem;
        }
      }
    }

    .mission-container {
      width: 100%;
      padding: 14rem 0;
      color: black;
      background-color: #ffbdd4;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;

      .circle-vector {
        position: absolute;
        width: 3%;
        right: 36%;
        top: 30.5%;
      }

      .vector-3 {
        position: absolute;
        right: 17%;
        top: 32.7%;
        width: 85%;
      }

      .heading-container {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .heading {
          margin: 0 0 0.5rem 0;
          font-size: 3rem;
        }
      }

      .mission-text {
        width: 80%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .text {
          font-weight: 200;
          width: 100%;
          font-size: 1.7rem;

          span {
            text-decoration: underline;
            font-weight: 400;
          }
        }
      }
    }

    .principles-container {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3rem 0;
      color: #009966;

      .vector-container {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          position: absolute;
          top: 1rem;
          z-index: -1;
        }
      }

      .heading-container {
        .heading {
          font-size: 3rem;
        }
      }

      .principles {
        width: 100%;
        display: flex;
        justify-content: space-between;
        border-bottom: 3px solid #009966;
        padding-bottom: 1rem;

        .principle {
          width: 30%;
          display: flex;
          flex-direction: column;
          text-align: center;
          justify-content: center;

          .title {
            font-size: 1.1rem;
            margin: 0;
          }

          .description {
            font-weight: 200;
            margin-top: 0.5rem;
            text-align: center;
          }
        }

        .principle:nth-child(1) {
          margin: 2rem 0 7rem 0;
        }

        .principle:nth-child(2) {
          margin: 8rem 0 3.5rem 0;
        }

        .principle:nth-child(3) {
          margin: 14rem 0 2rem 0;
        }
      }
    }

    .team-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .text-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
          margin-bottom: 0;
          font-size: 3rem;
        }

        .description-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .description {
            width: 70%;
            font-size: 1.3rem;
            font-weight: 300;
            text-align: center;
          }
        }
      }

      .team {
        margin-top: 2rem;
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 2.5rem;
        border-bottom: 3px solid #009966;

        .team-member {
          width: 18.5%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .img-container {
            width: 90%;

            img {
              width: 100%;
              border-radius: 50%;
            }
          }

          .member-details {
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .name {
              text-transform: uppercase;
              font-weight: bold;
              font-size: 1.2rem;
              margin-bottom: 0.5rem;
            }

            .role {
              margin-top: 0;
              font-style: italic;
              font-size: 1rem;
              text-align: center;
              font-weight: 300;
            }
          }
        }
      }
    }

    .backed-by__container {
      margin: 1.5rem 0;
      width: 95%;
      display: flex;
      flex-direction: column;

      .heading {
        font-size: 1.4rem;
        text-align: center;
      }

      .companies-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 1rem;

        .img-container {
          width: 20%;

          img {
            width: 100%;
          }
        }
      }
    }

    .simplest-container {
      width: 95%;
      display: flex;
      flex-direction: column;
      padding: 2rem 0;
      border-radius: 20px;
      align-items: flex-start;
      justify-content: center;
      background: url("../../assets/background2.png"), #f0ede6;
      background-repeat: no-repeat;
      background-size: cover;

      .img-container {
        width: 15%;
        margin-left: 2rem;

        img {
          width: 100%;
        }
      }

      .simplest-content {
        margin-left: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 5rem;
        width: 50%;
        color: #009966;

        .title {
          font-weight: bold;
          font-size: 1.5rem;
        }

        .description {
          text-align: left;
          margin-top: 0;
          font-weight: 300;
          font-size: 1.1rem;
        }
      }

      .btn-container {
        display: flex;
        width: 40%;
        align-items: center;
        justify-content: flex-start;
        margin-left: 2rem;

        .filled {
          margin-right: 4rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .homepage {
    .main-content__content {
      .vision-container {
        .vector-1,
        .vector-2 {
          display: none;
        }
      }

      .mission-container {
        .circle-vector,
        .vector-3 {
          display: none;
        }
      }

      .principles-container {
        .vector-container {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  .homepage {
    .main-content__content {
      .vision-container {
        .vector-1 {
          position: absolute;
          right: 7%;
          top: 30%;
          width: 72%;
          z-index: 1;
        }
      }

      .mission-container {
        .circle-vector {
          position: absolute;
          width: 3%;
          right: 35%;
          top: 30.5%;
        }

        .vector-3 {
          position: absolute;
          right: 15%;
          top: 32.7%;
          width: 85%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .homepage {
    .home-banner {
      .banner-text_container {
        font-size: 90px;
      }
    }

    .main-content__content {
      .vision-container {
        .vector-1 {
          position: absolute;
          right: 2%;
          top: 33%;
          width: 97%;
          z-index: 1;
        }

        .vision-text {
          .text {
            font-size: 1.4rem;
          }
        }
      }

      .heading-container {
        .sub-heading {
          text-align: center;
        }
      }

      .mission-container {
        .circle-vector {
          position: absolute;
          width: 4%;
          right: 30%;
          top: 28.5%;
        }

        .vector-3 {
          position: absolute;
          right: 4%;
          top: 30.7%;
          width: 110%;
          z-index: 1;
        }
      }

      .problem-solution__container {
        .problem-container {
          padding: 4rem 0 4.1rem 0;
        }
      }

      .simplest-container {
        .simplest-content {
          display: flex;
          align-items: flex-start;

          .title {
            text-align: left;
          }
        }

        .btn-container {
          .filled {
            margin-right: 4rem;
            padding: 0.7rem;

            .link {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .homepage {
    .home-banner {
      .banner-text_container {
        font-size: 60px;
      }
    }

    .main-content__content {
      .title-container {
        width: 80%;
      }

      .trusted-container {
        .description {
          font-size: 2.7rem;
          width: 80%;
        }
      }

      .vision-container {
        .vector-1,
        .vector-2 {
          display: none;
        }
      }

      .mission-container {
        .circle-vector,
        .vector-3 {
          display: none;
        }
      }

      .principles-container {
        .vector-container {
          display: none;
        }

        .principles {
          .principle:nth-child(1),
          .principle:nth-child(2),
          .principle:nth-child(3) {
            margin: 0 0 1.5rem 0;
          }
        }
      }

      .backed-by__container {
        .heading {
          font-size: 1.1rem;
        }
      }

      .team-container {
        .text-container {
          .description-container {
            .description {
              width: 80%;
            }
          }
        }
      }

      .simplest-container {
        .simplest-content {
          display: flex;
          align-items: flex-start;

          .title {
            text-align: left;
          }
        }

        .btn-container {
          width: 70%;

          .filled {
            margin-right: 4rem;
            padding: 0.7rem 1rem;
            width: 40%;

            .link {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 426px) {
  .homepage {
    .home-banner {
      .banner-text_container {
        .banner-text {
          font-size: 3.5rem;
        }
      }
    }

    .main-content__content {
      .title-container {
        width: 100%;
      }

      .problem-solution__container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .problem-container {
          width: 80%;
          margin-bottom: 2rem;
          font-weight: 300;

          .description {
            width: 90%;
          }
        }

        .solution-container {
          width: 80%;
          font-weight: 300;

          .description {
            width: 90%;
          }
        }
      }

      .btn-container {
        width: 85%;
      }

      .trusted-container {
        .description {
          font-size: 2.2rem;
          width: 90%;
        }
      }

      .vision-container {
        padding: 9rem 0;

        .heading-container {
          width: 90%;

          .sub-heading {
            font-size: 1.8rem;
          }
        }

        .vision-text {
          .text {
            font-size: 1.4rem;
          }
        }
      }

      .mission-container {
        padding: 9rem 0;

        .heading-container {
          width: 90%;

          .heading {
            text-align: center;
          }
        }

        .mission-text {
          .text {
            font-size: 1.4rem;
          }
        }
      }

      .principles-container {
        .vector-container {
          display: none;
        }

        .heading-container {
          width: 90%;

          .heading {
            text-align: center;
          }
        }

        .principles {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .principle:nth-child(1),
          .principle:nth-child(2),
          .principle:nth-child(3) {
            width: 80%;
            margin: 1rem 0;

            .title {
              font-size: 1.4rem;
            }

            .description {
              font-size: 1.2rem;
            }
          }
        }
      }

      .team-container {
        width: 100%;

        .text-container {
          width: 80%;

          .description-container {
            width: 100%;

            .description {
              width: 100%;
            }
          }
        }

        .team {
          display: flex;
          flex-direction: column;

          .team-member {
            width: 90%;
          }
        }
      }

      .backed-by__container {
        width: 100%;

        .heading {
          font-size: 1.1rem;
        }
      }

      .simplest-container {
        align-items: center;

        .img-container {
          width: 40%;
        }

        .simplest-content {
          width: 90%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-left: 0;

          .title {
            text-align: center;
          }

          .description {
            text-align: center;
            margin-top: 6rem;
          }
        }

        .btn-container {
          width: 90%;
          margin-left: 0;
          justify-content: space-between;

          .filled {
            padding: 0.7rem 0.5rem;
            width: 45%;
            margin-right: 0;

            .link {
              width: 100%;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}
