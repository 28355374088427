.ring {
  position: fixed;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: #ffbdd4;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  will-change: width, height, transform, border;
  z-index: 999;
  pointer-events: none;
}

// .follow-dot {
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   width: 8px;
//   height: 8px;
//   background-color: #009966;
//   border-radius: 100%;
//   transform: translate(-50%, -50%);
//   z-index: 999;
//   pointer-events: none;
// }

.ring.hovered {
  width: 50px;
  height: 50px;
  border: 3px solid #ffbdd4;
  background-color: rgba(255, 189, 212, 0.1);
}

// .follow-dot.hovered {
//   display: none;
// }
