// lejapage
.lejapage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f0ede6;
  overflow-x: hidden;
  margin-top: 4rem;

  // first banner mobile view
  .banner-img__container-phone {
    display: none;
  }

  // first banner
  .leja-banner {
    .left {
      .banner-img_container {
        // logo
        .leja-logo {
          width: 80%;
        }

        // pink line
        .banner-line {
          position: relative;
          right: 28rem;
          top: 2rem;
          transform: scaleX(-1);
        }
      }
    }

    .right {
      img {
        width: 84%;
        position: absolute;
        right: -7rem;
      }
    }
  }

  // simplest way banner
  .simplest-way_container {
    width: 100%;
    // why container
    .why-container {
      // why heading
      .why {
        .heading-phone {
          display: none;
        }

        .heading {
          font-size: 3rem;
        }
      }

      // reason container
      .reasons-container {
        background: url("../../assets/why-anim.gif");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    // testimonies container
    .testimonies-container {
      .testimonies {
        width: 100%;

        .testimony {
          transform: scale(0.5);
          transition: transform 300ms;
          opacity: 0.5;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .img-container {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
            }
          }

          .text-container {
            width: 90%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .name {
              font-weight: bold;
              font-size: 1.3rem;
              margin-bottom: 0;
            }

            .testimonial {
              font-weight: 300;
              font-style: italic;
              font-size: 1rem;
              text-align: center;
              margin-top: 0.5rem;
            }
          }
        }

        .arrow {
          position: absolute;
          z-index: 10;
        }

        .prev {
          top: 50%;
          left: 0;
        }

        .next {
          top: 50%;
          right: 0;
        }

        .active {
          opacity: 1;
          transform: scale(1);
          margin-top: 10rem;
        }
      }

      // testimonies
      .phone-testimonies {
        display: none;
      }

      .youtube {
        width: 80%;
        margin-top: 3rem;
        height: 600px;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .lejapage {
    margin-top: 3rem;

    .leja-banner {
      .left {
        // logo container
        .banner-img_container {
          // pink line
          .banner-line {
            display: none;
          }
        }
      }

      .right {
        padding: 0;
        margin-top: 4rem;
        position: relative;

        img {
          width: 100%;
          position: absolute;
          right: -2rem;
        }
      }
    }

    .simplest-way_container {
      width: 100%;
      // why container
      .why-container {
        .why {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
        // reason container
        .reasons-container {
          background: none;
          padding: 0 0 3rem 0;
          display: flex;
          flex-direction: column;

          .reason {
            width: 80%;
            margin-bottom: 1rem;
          }
        }
      }

      // testimonies container
      .testimonies-container {
        // testimonies
        .phone-testimonies {
          display: none;
        }

        .youtube {
          width: 90%;
          margin-top: 3rem;
          height: 600px;

          iframe {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 426px) {
  .lejapage {
    width: 100%;

    .leja-banner {
      position: relative;
      width: 100%;
      background-color: #f0ede6;
      display: flex;
      flex-direction: column;
      align-items: center;

      .left,
      .right {
        display: none;
      }

      .banner-img__container-phone {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-left: 2rem;

        .title {
          color: #009966;
          max-width: 80%;
          margin: 4rem 0 10rem 0;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
        }

        .banner-logo_container {
          width: 90%;
          margin-bottom: 2rem;
          display: flex;
          align-items: center;

          .leja-logo {
            width: 100%;
          }
        }

        .get-app_container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .get-app__btn {
            border: none;
            padding: 0.5rem 1.4rem;
            border-radius: 20px;
            background-color: #009966;
            color: #f0ede6;
            font-weight: 600;
            margin-bottom: 1rem;
            transition: all 0.2s ease-in-out;

            .link {
              text-decoration: none;
              color: white;
              font-weight: bold;
            }
          }

          .get-app__btn:hover {
            padding: 0.5rem 1.7rem;
            border: 2px solid #009966;
            background-color: #f0ede6;
            color: #009966;

            .link {
              color: #009966;
              padding-bottom: 0.1rem;
              border-bottom: 2px solid #ffbdd4;
            }
          }
        }
      }

      .banner-img_container {
        display: none;
      }
    }

    .simplest-way_container {
      .get-app_container,
      .title-container,
      .description-container {
        display: none;
      }

      .why-container {
        .why {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .heading-phone {
            display: flex;
            text-align: center;
            line-height: 1.4;
            font-size: 1.2rem;
            max-width: 95%;
            margin-top: 1.5rem;
          }
        }

        .reasons-container {
          display: flex;
          flex-direction: column;
          background-image: none;
          padding: 0 0 2rem 0;

          .reason {
            width: 100%;
            margin-bottom: 1rem;

            .reason-title {
              .title {
                font-size: 1.5rem;
              }
            }

            .reason-description {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;

              .description {
                max-width: 95%;
                font-size: 1.3rem;
              }
            }
          }

          .left,
          .right {
            margin-top: 0;
          }
        }
      }

      .testimonies-container {
        .phone-testimonies {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .testimony {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .testimony-text {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;

              .name {
                margin-bottom: 0;
              }

              .testimonial {
                max-width: 80%;
                font-weight: 100;
                font-style: italic;
                padding-bottom: 1rem;
              }
            }
          }
        }

        .testimonies {
          display: none;
        }
      }
    }
  }
}

.shadowed {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: -5px;
    width: 100%;
    height: 2.3%;
    background: transparent;
    border-radius: 50%;
    box-shadow: 0 50px 36px 1px #000000;
  }
}
