.transparent-navigation {
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  z-index: 11;

  .logo-container {
    margin-left: 2rem;
  }

  .nav-links-container {
    margin-right: 7rem;
    display: flex;
    width: 25%;
    justify-content: space-around;
    align-items: center;

    .links {
      color: #009966;
      font-weight: 400;
      text-decoration: none;
    }

    .links:hover {
      font-weight: bold;
      padding-bottom: 0.1rem;
      border-bottom: 3px solid #ffbdd4;
    }
  }

  .nav-drawer {
    display: none;
  }
}

@media only screen and (max-width: 1440px) {
  .navigation {
    .nav-links-container {
      margin-right: 4rem;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .navigation {
    .nav-links-container {
      margin-right: 2rem;
      width: 30%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .navigation {
    .nav-links-container {
      display: none;
    }

    .nav-drawer {
      display: block;

      .menu-icon {
        color: #009966;
      }
    }
  }

  .transparent-navigation {
    .nav-links-container {
      display: none;
    }

    .nav-drawer {
      display: block;

      .menu-icon {
        color: #009966;
      }
    }
  }
}

@media only screen and (max-width: 426px) {
  .navigation {
    display: flex;
    align-items: center;

    .logo-container {
      width: 40%;

      img {
        width: 100%;
      }
    }

    .nav-links-container {
      display: none;
    }

    .nav-drawer {
      display: block;

      .menu-icon {
        color: #009966;
      }
    }
  }

  .transparent-navigation {
    display: flex;
    align-items: center;

    .logo-container {
      width: 40%;

      img {
        width: 100%;
      }
    }

    .nav-links-container {
      display: none;
    }

    .nav-drawer {
      display: block;

      .menu-icon {
        color: #009966;
      }
    }
  }
}

.navigation {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  background-color: #f0ede6;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;

  .logo-container {
    margin-left: 2rem;
  }

  .nav-links-container {
    margin-right: 7rem;
    display: flex;
    width: 25%;
    justify-content: space-around;
    align-items: center;

    .links {
      color: #009966;
      font-weight: 400;
      text-decoration: none;
    }

    .links:hover {
      font-weight: bold;
      padding-bottom: 0.1rem;
      border-bottom: 3px solid #ffbdd4;
    }
  }

  .nav-drawer {
    display: none;
  }
}

.transparent-navigation {
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  z-index: 11;

  .logo-container {
    margin-left: 2rem;
  }

  .nav-links-container {
    margin-right: 7rem;
    display: flex;
    width: 25%;
    justify-content: space-around;
    align-items: center;

    .links {
      color: #009966;
      font-weight: 400;
      text-decoration: none;
    }

    .links:hover {
      font-weight: bold;
      padding-bottom: 0.1rem;
      border-bottom: 3px solid #ffbdd4;
    }
  }

  .nav-drawer {
    display: none;
  }
}

@media only screen and (max-width: 1440px) {
  .navigation {
    .nav-links-container {
      margin-right: 4rem;
    }
  }

  .transparent-navigation {
    .nav-links-container {
      margin-right: 4rem;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .navigation {
    .nav-links-container {
      margin-right: 2rem;
      width: 30%;
    }
  }

  .transparent-navigation {
    .nav-links-container {
      margin-right: 2rem;
      width: 30%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .navigation {
    .nav-links-container {
      display: none;
    }

    .nav-drawer {
      display: block;

      .menu-icon {
        color: #009966;
      }
    }
  }

  .transparent-navigation {
    .nav-links-container {
      display: none;
    }

    .nav-drawer {
      display: block;

      .menu-icon {
        color: #009966;
      }
    }
  }
}

@media only screen and (max-width: 426px) {
  .navigation {
    display: flex;
    align-items: center;

    .logo-container {
      width: 40%;

      img {
        width: 100%;
      }
    }

    .nav-links-container {
      display: none;
    }

    .nav-drawer {
      display: block;

      .menu-icon {
        color: #009966;
      }
    }
  }

  .transparent-navigation {
    display: flex;
    align-items: center;

    .logo-container {
      width: 40%;

      img {
        width: 100%;
      }
    }

    .nav-links-container {
      display: none;
    }

    .nav-drawer {
      display: block;

      .menu-icon {
        color: #009966;
      }
    }
  }
}
